$fonts: ((url('/fonts/avenir-roman.woff2') format('woff2')), 'Avenir', 400, normal),
  ((url('/fonts/avenir-medium.woff2') format('woff2')), 'Avenir', 500, normal),
  ((url('/fonts/avenir-heavy.woff2') format('woff2')), 'Avenir', 700, normal),
  ((url('/fonts/avenir-black.woff2') format('woff2')), 'Avenir', 900, normal);

@each $font-src, $font-name, $font-weight, $font-style in $fonts {
  @font-face {
    font-family: $font-name;
    src: $font-src;
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: swap;
  }
}
