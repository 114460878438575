/* ---FONTS--- */
@use 'sass:math';

$font-base: 'Avenir', system, -apple-system, '.SFNSText-Regular', 'San Francisco', 'Roboto',
  'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif;

/* FONT SIZE */
$BASE_FONT_SIZE: 10px;

@function rem($size) {
  $remSize: math.div($size, 10px);
  @return #{$remSize}rem;
}
